import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { DetalleExcelService } from '../detalle-proyecto/detalle-excel.service';
import { DetalleProyectoService } from '../detalle-proyecto/detalle-proyecto.service';

@Component({
  selector: 'app-descarga',
  templateUrl: './descarga.component.html',
  styleUrls: ['./descarga.component.scss']
})
export class DescargaComponent implements OnInit {

  estadoList = [
    { value: 0, name: 'Sin usar' },
    { value: 1, name: 'Completado' },
    { value: 2, name: 'Pendiente' },
    { value: 3, name: 'No es posible completar' }
  ];

  selectEstado = null;
  controlValue = null;
  codificacionList = null;
  selectCodificacion = null;

  labels = 'value';
  public dataList = [];

  constructor(
    public dialogRef: MatDialogRef<DescargaComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    public dialog: MatDialog,
    private detalleExcelService: DetalleExcelService,
    private detalleProyectoService: DetalleProyectoService,
  ) { }

  ngOnInit(): void {
  }

  downloadProyecto(){
    let body = {
      proyecto: Number(this.data.proyecto),
      identificador: this.data.identificador,
      estado: this.selectEstado,
      campoControl: this.data.control,
      control: this.controlValue,
      codificacion: this.selectCodificacion,
    };
    this.detalleProyectoService.downloadProyecto(body).
      subscribe((data)=>{
        this.detalleExcelService.downloadProyecto(this.data.nameProyecto, data, this.labels);
    },
    (error) => {
      // console.log(error);
    });
  }

  async countProyecto(){
    this.dataList = [];
    Swal.fire({
      title: 'Descargando registros. Esto puede tardar un poco',
      allowOutsideClick: false,
      onOpen: () =>{
        Swal.showLoading();
      }
    });
    for await (const cod of this.selectCodificacion) {
      let body = {
        proyecto: Number(this.data.proyecto),
        identificador: this.data.identificador,
        estado: this.selectEstado,
        campoControl: this.data.control,
        control: this.controlValue,
        codificacion: [cod],
      };
      // console.log(body)

      let respcount = await this.detalleProyectoService.countProyecto(body).toPromise();
      // console.log(respcount)
      let count = respcount?.[0]?.["resultado"]?.["cuenta"];
      let chunkList = this.chunks(count, 500);
      let c = 1;
      let dataChunkObj = {
        resultado: [],
        // resultado: [],
      };
      if(chunkList.length > 0){

        for await (const chunk of chunkList) {
          body["pagina"] = chunk;
          let resdata = await this.detalleProyectoService.downloadProyectoPag(body).toPromise();
          // console.log(resdata)
            if(c == 1) {
              dataChunkObj = resdata[0];
            }else{
              dataChunkObj["resultado"] = [...dataChunkObj["resultado"], ...resdata[0]["resultado"]]
            }
            c += 1; 
        }
        if(dataChunkObj) this.dataList.push(dataChunkObj);

      }else{
        body["pagina"] = 1;
        let resdata = await this.detalleProyectoService.downloadProyectoPag(body).toPromise();
        dataChunkObj = resdata[0];
        this.dataList.push(dataChunkObj);
      }
    }
    Swal.close();
    if(this.dataList.length > 0) this.detalleExcelService.downloadProyecto(this.data.nameProyecto, this.dataList, this.labels);
    else {
      Swal.fire(
        'Información',
        'No se encontraron registros a descargar.',
        'info'
      );
    }
  }


  chunks(total, grupo){
    const grupos: number[] = [];
    for (let i = 0; i < total / grupo; i++) {
        grupos.push((i + 1) * grupo - grupo);
    }
    return grupos
  }

}
